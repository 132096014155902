import { ComboboxOption } from '@headlessui/react';
import classNames from 'classnames';

type DropdownOption<T = string> = {
  value: T;
  label: string;
  description?: string;
  disabled?: boolean;
};
export type DropdownOptionPrimitiveProps<T = string> = {
  option: DropdownOption<T>;
  selected?: boolean;
  children: React.ReactNode;
};

export const DropdownOptionPrimitive = <T = string>({
  option,
  selected,
  children,
}: DropdownOptionPrimitiveProps<T>) => {
  return (
    <ComboboxOption
      disabled={option.disabled}
      className={classNames(
        'text-carbon-700 hover:text-carbon-800 hover:bg-carbon-50 cursor-pointer rounded-md',
        'select-none p-2 text-sm',
        option.disabled ? 'text-gray-400' : 'text-gray-900',
      )}
      value={option}
    >
      <div className="flex flex-col">
        <div className="flex justify-between text-sm">
          <div className={selected ? 'font-medium' : 'font-normal'}>
            <div className="flex flex-row gap-2 items-center whitespace-nowrap">
              {children}
            </div>
          </div>
        </div>
        {option.description && (
          <p className="text-xs text-carbon-500 font-normal">
            {option.description}
          </p>
        )}
      </div>
    </ComboboxOption>
  );
};
