import numbro from 'numbro';

import getLocale from './getLocale';
import { autoRound } from './math';

export default function formatNumber(n: number): string {
  if (n > 0 && n < 0.001) {
    // If the number is less than 0.001, we want to show it in scientific notation
    return n.toExponential(1);
  }
  return new Intl.NumberFormat(getLocale()).format(autoRound(n));
}

export function formatCount(value: number): string {
  return numbro(value).format({
    average: true,
    mantissa: 2,
    optionalMantissa: true,
    output: 'number',
  });
}

export function formatPercent(value: number): string {
  return numbro(value).format({
    mantissa: 1,
    optionalMantissa: true,
    output: 'percent',
  });
}

export function formatPercentDifference(percentDifference: number): string {
  return numbro(percentDifference).format({
    mantissa: 1,
    optionalMantissa: true,
    output: 'percent',
    forceSign: true,
  });
}

export function formatRelativeUncertainty(uncertainty: number): string {
  return `±${formatPercent(uncertainty)}`;
}
