'use client';
import type { CountryCode } from '@carbonfact/shared/src/geographical-areas';
import { CircleFlag } from '@carbonfact/ui-components/src/CircleFlag';
import {
  Combobox,
  ComboboxButton,
  ComboboxOptions,
  Transition,
} from '@headlessui/react';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import {
  DropdownOptionPrimitive,
  type DropdownOptionPrimitiveProps,
} from '../../primitives';
import { AutocompleteInputPrimitive } from '../../primitives/AutoCompleteInputPrimitive';
import { DropdownButtonPrimitive } from '../../primitives/DropdownButtonPrimitive';
import { DropdownOptionsScrollerPrimitive } from '../../primitives/DropdownOptionsScrollerPrimitive';

type OptionType = DropdownOptionPrimitiveProps<CountryCode>['option'];
export type DropdownCountryProps = {
  label?: string;
  options: OptionType[];
  value?: CountryCode;
  disabled?: boolean;
  backgroundColor?: string;
  onChange: (val: CountryCode) => void;
  className?: string;
  height?: string;
  showArrow?: boolean;
  autocomplete?: boolean;
};
export const Country = ({
  label,
  options,
  disabled,
  showArrow = true,
  backgroundColor,
  className = '',
  value,
  height = 'h-8',
  onChange,
  autocomplete,
}: DropdownCountryProps) => {
  const t = useTranslations();
  const [selected, setSelected] = useState(
    (value !== undefined && options.find((o) => o.value === value)) ||
      options[0],
  );
  const [autocompleteSearchTerm, setAutocompleteSearchTerm] = useState('');

  const [showOptions, setShowOptions] = useState(false);

  if (selected === undefined) {
    return null;
  }

  useEffect(() => {
    setSelected(
      (value !== undefined && options.find((o) => o.value === value)) ||
        options[0],
    );
  }, [value, options[0]]);

  if (options.length === 0) {
    return null;
  }
  let bgColor = 'bg-white';

  if (backgroundColor) {
    bgColor = backgroundColor;
  }
  const handleOnChange = (option: OptionType) => {
    setSelected(option);
    onChange(option?.value);
  };

  const filteredOptions = autocomplete
    ? options.filter((option) =>
        option.label
          .toLowerCase()
          .includes(autocompleteSearchTerm.toLowerCase()),
      )
    : options;

  const isDisabled = disabled || options.length <= 1;
  return (
    <Combobox disabled={isDisabled} value={selected} onChange={handleOnChange}>
      {() => (
        <>
          <div
            className={classNames(
              'relative border-carbon-100 bg-carbon-50',
              height,
              className,
            )}
          >
            {(!autocomplete || (autocomplete && !showOptions)) && (
              <ComboboxButton
                className={classNames(
                  'rounded-md shadow-carbon border-[1px] box-border border-carbon-100 items-center px-1 focus:outline-none h-full w-full justify-between flex flex-row',
                  bgColor,
                )}
                onClick={() => {
                  setShowOptions(!showOptions);
                  setAutocompleteSearchTerm('');
                }}
              >
                <DropdownButtonPrimitive
                  disabled={isDisabled}
                  showArrow={showArrow}
                  prefix={label}
                >
                  {selected && (
                    <div className="w-5">
                      <CircleFlag countryCode={selected.value} />
                    </div>
                  )}
                  {selected && (
                    <p className="text-sm font-medium whitespace-nowrap overflow-hidden text-ellipsis">
                      {selected.label}
                    </p>
                  )}
                </DropdownButtonPrimitive>
              </ComboboxButton>
            )}
            {showOptions && (
              <Transition
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setShowOptions(false)}
              >
                <ComboboxOptions
                  static
                  className="absolute p-2 left-0 z-50 w-72 origin-top-right max-h-[50vh] overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  {autocomplete && (
                    <AutocompleteInputPrimitive
                      placeholder={value}
                      label={label}
                      onChange={setAutocompleteSearchTerm}
                    />
                  )}
                  {filteredOptions.length === 0 && (
                    <div className="p-1 text-sm text-carbon-500">
                      {t('Dropdown.noResultFound')}
                    </div>
                  )}

                  <DropdownOptionsScrollerPrimitive>
                    {filteredOptions.map((option) => (
                      <DropdownOptionPrimitive
                        key={option.value}
                        option={option}
                        selected={option.value === value}
                      >
                        <CircleFlag countryCode={option.value} />
                        {option.label}
                      </DropdownOptionPrimitive>
                    ))}
                  </DropdownOptionsScrollerPrimitive>
                </ComboboxOptions>
              </Transition>
            )}
          </div>
        </>
      )}
    </Combobox>
  );
};
